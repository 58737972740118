/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': { 
            init: function () {

                // Navigation
                var navMenu = $('#nav.main__nav');
                var navOpen = $('#nav__open');
                var navClose = $('#nav__close');
                var ulMenu = $('#menu-main');
                ulMenu.attr('aria-haspopup', 'true');
                ulMenu.attr('tabindex', '-1');

                $('body').focusin(function (e) {
                    if ($(navMenu)[0] !== undefined && !$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function (event) {
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );

                // toggle sub-menu
                var menuMainItems = document.querySelectorAll('.main_menu li.menu-item-has-children');
                
                function setAria(){
                    if ($(window).width() > 820) {

                        menuMainItems.forEach(function(item) {
                            item.querySelector('a').setAttribute('aria-expanded', "true");
                            item.querySelector('a').setAttribute('tabindex', "-1");
                        });

                    } else {
                        menuMainItems.forEach(function(item) {
                            if ( item.classList.contains('open') ) {
                    			item.querySelector('a').setAttribute('aria-expanded', "true");
                                item.querySelector('a').removeAttribute('tabindex');
                    		} else {
                    			item.querySelector('a').setAttribute('aria-expanded', "false");
                                item.querySelector('a').removeAttribute('tabindex');
                    		}
                        });
                    }
                }
                setAria();
                $(window).resize(function() { setAria(); });

                

                Array.prototype.forEach.call(menuMainItems, function (el, i) {
                    el.querySelector('a').addEventListener("click", function (event) {

                        // tolgo class open da tutti gli a
                        menuMainItems.forEach(function (item) {
                            item.classList.remove('open');
                        });

                        if (this.parentNode.classList.contains('open')) {
                            this.parentNode.classList.remove('open');
                        } else {
                            this.parentNode.classList.add('open');
                        }
                        event.preventDefault();

                        return false;
                    });

                    // focus
                    el.addEventListener("focusin", function (event) {
                        this.classList.add('open');
                    });
                    el.addEventListener("focusout", function (event) {
                        this.classList.remove('open');
                    });
                });

                
                var menuHeaderItems = document.querySelectorAll('.header_menu_mobile li.menu-item-has-children');
                Array.prototype.forEach.call(menuHeaderItems, function(el, i){

                    // click
                	el.querySelector('a').addEventListener("click",  function(event){
                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                			this.setAttribute('aria-expanded', "false");
                		} else {
                			this.parentNode.classList.add('open');
                            this.setAttribute('aria-expanded', "true");
                		}
                		event.preventDefault();
                		return false;
                	});
                });

                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 120,
                    once: true,
                });

                document.querySelectorAll('img')
                    .forEach(function (img) {
                        img.addEventListener('load', function () {
                            AOS.refresh();
                        });
                    });

                /*\ !! SLICK SLIDER !! \*/
  
                //Slider HP

                var gallerySliderSingle = $('.gallery--single');

                gallerySliderSingle.slick({
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: false,
                    dots: false,
                    slidesToShow: 4.3,
                    slidesToScroll: 1,
                    rows: 0, 
                  
                    responsive: [
                        {
                            breakpoint: 1800,
                            settings: {
                                slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 3.5,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 1300,
                            settings: {
                                slidesToShow: 2.5,
                            }
                        },
                        {
                            breakpoint: 1150,
                            settings: {
                                slidesToShow: 2.2,
                            }
                        },
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 1.6,
                            }
                        },
                        {
                          breakpoint: 800,
                          settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: false,
                          }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                              slidesToShow: 1.7,
                              arrows: false,
                              dots: false,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                              slidesToShow: 1.5,
                              arrows: false,
                              dots: false,
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                              slidesToShow: 1.2,
                              arrows: false,
                              dots: false,
                            }
                        },
                        {
                            breakpoint: 460,
                            settings: {
                              slidesToShow: 1,
                              arrows: false,
                              dots: false,
                            }
                        }
                      ]
                });

                //Slider pagina cantiere
                var gallerySliderImage = $('.gallery--image');


                gallerySliderImage.slick({
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: false,
                    dots: false,
                    adaptiveHeight: false,
                    slidesToShow: 5.4,
                    slidesToScroll: 1,
                    //prevArrow: $(".pp2"),
                    //nextArrow: $(".nn2"),
                    rows: 0, 
                    responsive: [
                        {
                            breakpoint: 1500,
                            settings: {
                                slidesToShow: 5, 
                                arrows: true,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 4.5,
                            }
                        },
                        {
                            breakpoint: 1300,
                            settings: {
                                slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3.8,
                            }
                        },
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 3.5,
                            }
                        },
                        {
                            breakpoint: 820,
                            settings: {
                                slidesToShow: 2.3,
                                arrows: false,
                                dots: true,
                            }
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 1.8,
                            arrows: false,
                            dots: true,
                          }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                              slidesToShow: 1.2,
                              arrows: false,
                              dots: true,
                            }
                        }

                      ]
                });
                
                var totalSlideCaratteristiche = $('.gallery--image .slick-slide:not(.slick-cloned)').length;

                // Torno indietro alla fine dello slide
                $('.gallery--image').on('beforeChange', function (event, slick, currentSlide, nextSlide) {

                    var prevArrow = document.querySelectorAll('.gallery--image .pp2');
                    var nextArrow = document.querySelectorAll('.gallery--image .nn2');

                    // disabilito arrow se arrivo alla fine o sono all'inizio
                    if (nextSlide > 0) {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });

                    } else {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    }

                    if (nextSlide == totalSlideCaratteristiche - 1) {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    } else {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });
                    }
                });

                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                            function (item) {
                                return item.el.find('img').attr('alt');
                            }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">' +
                            '<div class="mfp-close"></div>' +
                            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'embed/', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            }
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function () { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        closeBtnInside: true,
                        closeOnBgClick: true,
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function (item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Gallery
                var wysiwygGallery = $('.text--wysiwyg .gallery');
                wysiwygGallery.each(function () { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function (item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Arrow to top
                var top_arrow = $('.top_arrow');
                var htmlBody = $('html, body');
                window.addEventListener('scroll', function () {
                    var distanceTop = htmlBody.scrollTop();
                    if (distanceTop > 100) {
                        top_arrow.fadeIn();
                    } else {
                        top_arrow.fadeOut();
                    }
                });

                // aggiunta classe a header fixed quando si scrolla la pagina
                var header = document.querySelector("header");
                var mainMenu = document.querySelector('#nav');
                window.addEventListener("scroll", function () {
                    var stickyTop = window.scrollY;

                    if (stickyTop > 50) {
                        header.classList.add('sticky');
                    } else {
                        if (header.classList.contains('sticky')) {
                            header.classList.remove('sticky');
                        }
                    }
                });
                // controllo altezza header anche nel document.ready
                var scrollY = window.scrollY;
                if (scrollY > 50) {
                    header.classList.add('sticky');
                }

                // accordion tabs dettaglio modelli o prodotti
                var tabsDettaglio = document.querySelectorAll('.detail_accordion_row');

                if (tabsDettaglio) {
                    tabsDettaglio.forEach(function (item) {
                        item.addEventListener('click', function (e) {
                            var tabsDettaglio2 = document.querySelectorAll('.detail_accordion_row');
                            tabsDettaglio2.forEach(function (item2) {
                                if (item2.classList.contains('active')) {
                                    item2.classList.remove('active');
                                }
                            });

                            var parentDiv = e.target.closest('div');
                            if (parentDiv) {
                                parentDiv.classList.add('active');
                            }
                        });
                    });
                }


                // evento facetwp-refresh (reinizializzo scripts) 
                document.addEventListener('facetwp-refresh', function() {
                    if (FWP.loaded) {
                        FWP.setHash();
                        console.log('refreshato facetwp');

                        // riattivo scripts calcolo rata
                        setTimeout(function() {
                            initializeCalcoloRata();
                        }, 3000);
                    }
                });

                /**
                 * AUTENTICAZIONE PER REST API
                 */
                // Set state object with values that are changed programatically
                var state = {
                    loggedIn: false,
                    restUrl: "/wp-json/",
                    token: "wp-token",
                };

                function setState(toSet, newValue) {
                    state[toSet] = newValue;
                }

                // Controllo se l'utente è già autenticato o devo inizializzare il login
                if (cookie.get(state.token) === undefined) {
                    //logout();
                    //initLogin();
                } else {
                    //login();
                    //initLogout();
                }

                // setto variabile loggedIn a false
                function logout() {
                    setState("loggedIn", false);
                    console.log(state);
                }

                // setto variabile loggedIn a false
                function login() {
                    setState("loggedIn", true);
                    console.log(state);
                }

                // processo di login
                function initLogin() {
                    // recupero form del login
                    var formLogin = document.querySelector('#gform_0');
                    if (formLogin) {
                        // creo listener sul submit del form
                        formLogin.addEventListener('submit', function (e) {
                            e.preventDefault();

                            var creds = {
                                username: document.querySelector('#input_1').value,
                                password: document.querySelector('#input_2').value
                            }; // 1

                            /* var creds = {
                                username: 'system',
                                password: 'Archi15media',
                            };  */

                            if (creds.username !== "" && creds.password !== "") {
                                // Eseguo request per autenticarmi sulle REST API di Wordpress
                                axios({
                                    method: "post",
                                    url: state.restUrl + "api/v1/token",
                                    data: 'username=' + creds.username + '&password=' + creds.password,
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded"
                                    }
                                }) // 2
                                    .then(function (response) {
                                        console.log(response, response.status);
                                        if (200 === response.status) {
                                            console.log(response.data);
                                            cookie.set(state.token, response.data.jwt_token, {
                                                expires: 1,
                                                secure: false,
                                                path: '/',
                                            }); // 3

                                            if (cookie.get('wp-token') !== undefined) {
                                                // faccio il submit del form
                                                formLogin.submit();
                                            }

                                        } else {
                                            // Eseguito quando ricevo una reponse con status != 200 
                                            alert("Login failed, please check credentials and try again!");
                                        }
                                    }) // 5
                                    .catch(function (error) {
                                        // Loggo l'errore attuale
                                        console.error(error);

                                        // eseguo comunque il submit per mostrare l'errore di Wordpress all'utente
                                        formLogin.submit();
                                    });
                            }
                        });
                    }
                }

                // processo di logout
                function initLogout() {
                    var logoutLink = document.querySelector('.logout a');

                    if (logoutLink) {
                        logoutLink.addEventListener('click', function (e) {
                            e.preventDefault();
                            cookie.removeSpecific(state.token, {
                                expires: 1,
                                secure: false,
                                path: '/',
                            });

                            logout();

                            // eseguo redirect per logout di Wordpress
                            var logoutUrl = e.target.href;
                            document.location = logoutUrl;
                        });
                    }
                }

                /**
                 * FINE AUTENTICAZIONE
                 */

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },


        // Page template Homepage
        'page_template_homepage': {
            init: function () {

                //GSAP HOMEPAGE
                gsap.from('.gsap_fade_in_1', {duration: 1, opacity: 0, delay: 1.25});
                gsap.from('.gsap_fade_in_2', {duration: 1, opacity: 0, delay: 0.5});
                gsap.from('.gsap_fade_in_3', {duration: 1, opacity: 0, delay: 1.5});
                gsap.from('.gsap_fade_in_4', {duration: 2, opacity: 0, delay: 1.75});
            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },


        // Page template Prodotti
        'page_template_prodotti': {
            init: function () {
                var boxElements = document.querySelectorAll(".box__header");
                
                boxElements.forEach(function(box) {
                    box.addEventListener("click", function () {
                        var boxContent = this.closest(".box").querySelector(".box__content");
                        var arrow = this.querySelector(".arrow"); 

                        if (boxContent.style.display === "none" || boxContent.style.display === "") {
                            boxContent.style.display = "block";
                            arrow.classList.add("rotate");
                        } else {
                            boxContent.style.display = "none";
                            arrow.classList.remove("rotate");
                        }
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        


    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
